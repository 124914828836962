import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import "./markdown.scss";
import {Helmet} from "react-helmet";

const Markdown = ({children, className, ...rest}) => {
  const [scripts, setScripts] = useState([]);

  useEffect(() => {
    if (!children || !children.match) {
      return setScripts([]);
    }

    let scripts = children.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi) || [];
    setScripts(scripts.map(s => s
      .replace(/<script[\s\S]*?>/gi, "")
      .replace(/<\/script>/gi, ""),
    ));
  }, [children]);

  return <>
    <ReactMarkdown
      className={classNames(["markdown", className])}
      linkTarget="_blank"
      allowDangerousHtml
    >
      {children}
    </ReactMarkdown>
    <Helmet>
      {scripts.map((s, i) => {
        return <script key={i}>{s}</script>;
      })}
    </Helmet>
  </>;
};

Markdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Markdown;
